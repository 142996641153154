<template>
	<div class="p-2">
		<b-row>
			<b-col cols="6">
				<b-card class="jsf-card-primary">
					<b-card-title class="text-center">示例图表</b-card-title>
					<b-card-text id="container" style="height: 300px"> </b-card-text>
				</b-card>
			</b-col>
			<b-col cols="6">
				<b-card class="jsf-card-primary">
					<b-card-title class="text-center">示例图表</b-card-title>
					<b-card-text id="container1" style="height: 300px"> </b-card-text>
				</b-card>
			</b-col>
		</b-row>

		<b-row>
			<b-col cols="4">
				<b-card class="jsf-card-primary">
					<b-card-title class="text-center">示例图表</b-card-title>
					<b-card-text id="container2" style="height: 300px"> </b-card-text>
				</b-card>
			</b-col>
			<b-col cols="4">
				<b-card class="jsf-card-primary">
					<b-card-title class="text-center">示例图表</b-card-title>
					<b-card-text id="container3" style="height: 300px"> </b-card-text>
				</b-card>
			</b-col>
			<b-col cols="4">
				<b-card class="jsf-card-primary">
					<b-card-title class="text-center">示例图表</b-card-title>
					<b-card-text id="container4" style="height: 300px"> </b-card-text>
				</b-card>
			</b-col>
		</b-row>
	</div>
</template>

<script>
	import { onMounted, reactive, toRefs } from "@vue/composition-api";
	import { Bar, Column, Line, measureTextWidth, Pie } from "@antv/g2plot";

	export default {
		name: "index",
		setup() {
			const data = reactive({
				energyConsumption: [
					{
						title: "今日能耗",
						today: 123,
						prev: "昨日",
						yesterday: 321,
						percent: {
							isUp: false,
							value: 5,
						},
					},
					/* {
            title: "今日碳排放",
            today: 123,
            yesterday: 321,
            percent: {
                isUp: false,
                value: 5,
            },
        }, */
					{
						title: "本周能耗",
						today: 123,
						prev: "上周",
						yesterday: 321,
						percent: {
							isUp: false,
							value: 5,
						},
					},
					{
						title: "本月能耗",
						today: 123,
						prev: "上月",
						yesterday: 321,
						percent: {
							isUp: true,
							value: 5,
						},
					},
					{
						title: "今年能耗",
						today: 123,
						prev: "去年",
						yesterday: 321,
						percent: {
							isUp: false,
							value: 5,
						},
					},
				],
			});

			const initLine = () => {
				fetch("https://gw.alipayobjects.com/os/bmw-prod/55424a73-7cb8-4f79-b60d-3ab627ac5698.json")
					.then((res) => res.json())
					.then((data) => {
						const line = new Line("container", {
							data,
							xField: "year",
							yField: "value",
							seriesField: "category",
							legend: false,
							xAxis: {
								type: "time",
							},
							yAxis: {
								label: {
									// 数值格式化为千分位
									formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
								},
							},
						});

						line.render();
					});
			};
			const initColumn = () => {
				const data = [
					{
						name: "London",
						月份: "Jan.",
						月均降雨量: 18.9,
					},
					{
						name: "London",
						月份: "Feb.",
						月均降雨量: 28.8,
					},
					{
						name: "London",
						月份: "Mar.",
						月均降雨量: 39.3,
					},
					{
						name: "London",
						月份: "Apr.",
						月均降雨量: 81.4,
					},
					{
						name: "London",
						月份: "May",
						月均降雨量: 47,
					},
					{
						name: "London",
						月份: "Jun.",
						月均降雨量: 20.3,
					},
					{
						name: "London",
						月份: "Jul.",
						月均降雨量: 24,
					},
					{
						name: "London",
						月份: "Aug.",
						月均降雨量: 35.6,
					},
					{
						name: "Berlin",
						月份: "Jan.",
						月均降雨量: 12.4,
					},
					{
						name: "Berlin",
						月份: "Feb.",
						月均降雨量: 23.2,
					},
					{
						name: "Berlin",
						月份: "Mar.",
						月均降雨量: 34.5,
					},
					{
						name: "Berlin",
						月份: "Apr.",
						月均降雨量: 99.7,
					},
					{
						name: "Berlin",
						月份: "May",
						月均降雨量: 52.6,
					},
					{
						name: "Berlin",
						月份: "Jun.",
						月均降雨量: 35.5,
					},
					{
						name: "Berlin",
						月份: "Jul.",
						月均降雨量: 37.4,
					},
					{
						name: "Berlin",
						月份: "Aug.",
						月均降雨量: 42.4,
					},
				];

				const stackedColumnPlot = new Column("container1", {
					data,
					isGroup: true,
					xField: "月份",
					yField: "月均降雨量",
					seriesField: "name",
					legend: false,
				});

				stackedColumnPlot.render();
			};
			const initPie = () => {
				function renderStatistic(containerWidth, text, style) {
					const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
					const R = containerWidth / 2;
					// r^2 = (w / 2)^2 + (h - offsetY)^2
					let scale = 1;
					if (containerWidth < textWidth) {
						scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1);
					}
					const textStyleStr = `width:${containerWidth}px;`;
					return `<div style="${textStyleStr};font-size:${scale}em;line-height:${scale < 1 ? 1 : "inherit"};">${text}</div>`;
				}

				const data = [
					{ type: "分类一", value: 27 },
					{ type: "分类二", value: 25 },
					{ type: "分类三", value: 18 },
					{ type: "分类四", value: 15 },
					{ type: "分类五", value: 10 },
					{ type: "其他", value: 5 },
				];

				const piePlot = new Pie("container2", {
					appendPadding: 10,
					data,
					angleField: "value",
					colorField: "type",
					radius: 1,
					innerRadius: 0.64,
					meta: {
						value: {
							formatter: (v) => `${v} ¥`,
						},
					},
					label: {
						type: "spider",
						offset: "-50%",
						style: {
							textAlign: "center",
						},
						autoRotate: false,
						content: "{value}",
					},
					legend: {
						layout: "horizontal",
						position: "bottom",
					},
					statistic: {
						title: {
							offsetY: -4,
							customHtml: (container, view, datum) => {
								const { width, height } = container.getBoundingClientRect();
								const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
								const text = datum ? datum.type : "总计";
								return renderStatistic(d, text, { fontSize: 28 });
							},
						},
						content: {
							offsetY: 4,
							style: {
								fontSize: "32px",
							},
							customHtml: (container, view, datum, data) => {
								const { width } = container.getBoundingClientRect();

								const text = datum ? `¥ ${datum.value}` : `¥ ${data.reduce((r, d) => r + d.value, 0)}`;
								return renderStatistic(width, text, {
									fontSize: 32,
								});
							},
						},
					},
					// 添加 中心统计文本 交互
					interactions: [{ type: "element-selected" }, { type: "element-active" }, { type: "pie-statistic-active" }],
				});

				piePlot.render();
			};
			const initBar = () => {
				const data = [
					{
						label: "Mon.",
						type: "series1",
						value: 2800,
					},
					{
						label: "Mon.",
						type: "series2",
						value: 2260,
					},
					{
						label: "Tues.",
						type: "series1",
						value: 1800,
					},
					{
						label: "Tues.",
						type: "series2",
						value: 1300,
					},
					{
						label: "Wed.",
						type: "series1",
						value: 950,
					},
					{
						label: "Wed.",
						type: "series2",
						value: 900,
					},
					{
						label: "Thur.",
						type: "series1",
						value: 500,
					},
					{
						label: "Thur.",
						type: "series2",
						value: 390,
					},
					{
						label: "Fri.",
						type: "series1",
						value: 170,
					},
					{
						label: "Fri.",
						type: "series2",
						value: 100,
					},
				];

				const stackedBarPlot = new Bar("container3", {
					data,
					isGroup: true,
					xField: "value",
					yField: "label",
					/** 自定义颜色 */
					// color: ['#1383ab', '#c52125'],
					seriesField: "type",
					marginRatio: 0,
					legend: {
						layout: "horizontal",
						position: "bottom",
					},
					label: {
						// 可手动配置 label 数据标签位置
						position: "middle", // 'left', 'middle', 'right'
						// 可配置附加的布局方法
						layout: [
							// 柱形图数据标签位置自动调整
							{ type: "interval-adjust-position" },
							// 数据标签防遮挡
							{ type: "interval-hide-overlap" },
							// 数据标签文颜色自动调整
							{ type: "adjust-color" },
						],
					},
				});

				stackedBarPlot.render();
			};
			const initPieS = () => {
				const data = [
					{ type: "分类一", value: 27 },
					{ type: "分类二", value: 25 },
					{ type: "分类三", value: 18 },
					{ type: "分类四", value: 15 },
					{ type: "分类五", value: 10 },
					{ type: "其他", value: 5 },
				];

				const piePlot = new Pie("container4", {
					appendPadding: 10,
					data,
					angleField: "value",
					colorField: "type",
					radius: 0.75,
					label: {
						type: "spider",
						labelHeight: 28,
						content: "{name}\n{percentage}",
					},
					legend: {
						layout: "horizontal",
						position: "bottom",
					},
					interactions: [{ type: "element-selected" }, { type: "element-active" }],
				});

				piePlot.render();
			};
			onMounted(() => {
				initLine(), initColumn(), initPie(), initBar(), initPieS();
			});
			return {
				...toRefs(data),
			};
		},
	};
</script>

<style scoped></style>
